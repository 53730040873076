<template>

<div class="container sections_page" style="margin-top:10%;min-height:600px" >
  <div
       v-if="this.loading"
        class="d-flex flex-row justify-content-center loading_card"
      >
        <!-- LOADER -->
        <div class="loading_card_spinner">
  <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
          
        />
     
</div>
<!-- END LOADER -->

      </div>
  
  <div class="d-flex justify-content-between">
    <a v-if="this.items.length != 0" class="delete_cart" @click="show = true">
          <h6>
            <span>
              <i class="bi bi-eraser-fill"></i>
            </span>
            {{ $t("Emptycart") }}
          </h6>
        </a>
        <div
          v-if="this.$localStorage.get(this.$store.storeName) == null && this.items.length != 0"
          class="login_alert_cart"
        >( {{ $t("loginMessage1") }}  <router-link  class=""
          style="color:#9c0008 ; text-decoration: 2px underline !important;
           text-underline-offset: 5px; font-weight: bold;"
          :to="{
            name: 'login',
            params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
          }">{{ $t("loginMessage2") }}</router-link>  {{ $t("loginMessage3") }} )
          </div>

  </div>
  <table v-if="this.items.length != 0"> 
    <thead>
      <th>{{$t('product')}} <span style="color: #B5B5B5; padding-left: 7%;" class="cart_mobile"> {{$t('items')}}</span></th>
      <th><span class="cart_mobile">{{$t('Details')}}</span></th>
      <th>{{$t('quantity')}}</th>
      <th>{{$t('price')}}</th>
      <th style="color:red"><span class="cart_mobile">{{$t('empty item')}} <i class="fa fa-trash-o"></i></span></th>
    </thead>
   <tbody>
   <cart_item 
   v-for="item in this.items" :key="item" 
                                :item-id="item"
                                v-if="item"
   >

   </cart_item>
    </tbody>
  </table>
  <div v-else>
 <div class="container">
          <h4
            style="text-align: center; text-decoration: 2px underline; text-underline-offset: 3px; margin-top:1%;"
          >{{ $t('cartEmpty')}} !!</h4>
         
    
      <img
        v-if="this.items.length == 0"
        style="width: 50%; margin-left: 25%; margin-right: 25%;    
        filter: hue-rotate(248deg)"
        src="https://etrolley.net/store/assets/image/emptyCard.gif"
      />

      
       </div>
                          </div>

<div style="padding-top: 2%;">
               <i class="fa fa-plus" style="color: #c9c9c9;"></i>
           
               <a 
               style="
                font-size:16px;
                color: var(--primary-color);
                text-decoration: none;
                cursor: pointer;
              " @click="$router.push({
              name: 'home',
              params: { storeName: $store.storeName,lang:$store.state.routeLang },
            })
            ">{{$t('Add More Items')}}</a>
</div>
<div v-if="items.length!=0" style="margin-top: 2%;">
<h4> {{ $t('notes') }}</h4>
<textarea  class="note" :placeholder="$t('write your note here ..')" >
</textarea>
</div>
<h5 style="text-align: center; margin-top: 2%;font-weight:bold">{{$t('total')}}: 
  <span style="color: var(--primary-color);font-weight:bold">{{
              
                ((this.price +
                 ($store.state.tax * this.price) / 100 

                 )*$store.state.rate).toFixed($store.state.price_digit)
             }} {{ $store.state.UnitPrice }}</span></h5>

<router-link class="butt_green center_align cart_butt" style="margin-top: 4%; margin-bottom: 4%;"
v-if="this.$localStorage.get(this.$store.storeName)&&(this.items.length != 0)"
               
               :to="{
             name: 'checkout',
          params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                    }"
> {{ $t('confirmOrder') }}</router-link>

<router-link class="butt_green center_align cart_butt" style="margin-top: 4%; margin-bottom: 4%;"
v-else-if="(this.items.length != 0)"
               
               :to="{
             name: 'guest_confirmOrder',
          params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                    }"
> {{ $t('confirmOrder') }}</router-link>

<!-- <router-link class="butt_green center_align cart_button" style="text-align:center;margin-top: 4%; margin-bottom: 4%;"
v-if="!this.$localStorage.get(this.$store.storeName)&&(this.items.length != 0)"
               
               :to="{
             name: 'login',
          params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                    }"
> {{ $t('logIn') }}</router-link> -->

<router-link class="butt_green center_align cart_butt" style="margin-top: 2%; margin-bottom: 2%;"
v-if="(this.items.length == 0)"
               
               :to="{
             name: 'home',
          params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                    }"
> {{ $t('Continueshopping') }}</router-link>

<Transition name="modal">
      <div v-if="show" class="modal-mask ">
        <div class="modal-wrapper">
          <div class="modal-container float_card">
            <div class="modal-header">
              <slot name="header"><h6 style="width:100%;text-align:center">{{$t('confirmdeleteAll')}}</h6></slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button close" @click="deleteAllCookies() ">{{$t('confirm')}}</button>
                <button
                  class="modal-default-button cancel "
                  @click="(show = false), $emit('close')"
                >{{$t('cancel')}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>

</div>
</template>

<script>
import cart_item from "../components/cart_item_shopping.vue";
export default {
  components: { cart_item},
  data: function() {
    return {
      unit: this.$store.state.unit,
      langChange:'',
      items: [],
      products: [],
      loading: false,
      show: false,
      price: 0,
    };
  },
  watch: {
    langChange() {
      
console.log('///////////11111111111')
      this.loading=true;
      if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    let AllCookies = this.$cookies.keys();
    this.products = [];
    this.items = [];
    this.price = 0;

    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));

        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
        //console.log(this.items);
      }
    }
    console.log(this.items);

    
        this.loading=false;
  }
  },
  updated() {
    this.langChange=this.$i18n.locale
    console.log('/*******************/',this.langChange)
    
  },
  created() {
   
    if(this.$route.params.lang&&this.$route.params.lang!=this.$i18n.locale){
      this.$i18n.locale=this.$route.params.lang;this.$store.state.UnitPrice=this.$store.state.unitAR
      if(this.$i18n.locale=='en')
        {document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }}
    this.loading=true;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    let AllCookies = this.$cookies.keys();
    this.products = [];
    this.price = 0;

    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));

        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
        //console.log(this.items);
      }
    }
    console.log(this.items);
        if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
      setTimeout(() => {
      this.loading = false;
      }, 200);
  },
  methods: {
    updateProducts() {
      // console.log("hiiiiiiiiiiiiiii");
      this.items = [];
      this.products = [];
      this.price = 0;

      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));

          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
          //console.log(this.items);
        }
      }
    },
    updatedata() {

      this.loading = true;
      this.items = [];
      this.products = [];
      this.price = 0;
      
      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));

          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
          console.log(this.items);
        }
      }
      this.$store.state.langchange = false;
      setTimeout(() => {
        // this.updateall();
        this.loading = false;
      }, 0.1);
    },
    deleteAllCookies() {
      this.loading = true;
      for (let i = 0; i < this.items.length; i++) {
        this.$cookies.remove(this.items[i]);
      }
      this.items = [];
      this.price=0;

      console.log("hiiiiiiii" + this.$cookies.keys());
      this.$store.state.cartCounter = 0;
      this.loading = false;
      this.show = false;
    },
    confirmOrder() {
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/check_all_item_card",
          {
            products: this.products
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.$router.push({
              name: "confirmOrder",
              params: { storeName: this.$store.storeName,lang: this.$i18n.locale }
            });
          } else {
            alert(response.data.message);
            // this.couponMsg = response.data.message;
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                     window.location.href="/";

          }
        });
    }
  }
};
</script>