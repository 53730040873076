<template>
    <section style="min-height:600px;margin-top:10%" class="sections_page">
      <div v-if="this.loading" class="d-flex flex-row justify-content-center loading_card">
        <!-- LOADER -->
        <div class="loading_card_spinner">
          <hollow-dots-spinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />
  
        </div>
        <!-- END LOADER -->
  
      </div>
      <div v-else-if="items.length != 0" class="container">
        <h3 style="text-align: center;margin-top: 5%;">{{ $t('Checkout') }}</h3>

        <div class="text-center">
  <button
    class="butt_green cart_butt center_align login_guest" @click="$router.push({
                                 name: 'login',
                                 params: {
                                 storeName: $store.storeName,
                                   lang: $i18n.locale
                                            },
                                   });">
      {{ $t('Already have an account? order guest') }}
  </button>


</div>

<br>

<div class="text-center">
  <label>

    <input type="checkbox" @change="funshow_guest" />
    {{ $t('continue as guest') }}
  </label>
</div>
<br>
<div  v-if="show_guest"  class="row">
<div class="col-lg-6 col-md-6 col-12 check_out_col-6">
  
    <h4 class="account_order_info">{{ $t("name") }} <i style="color: red">*</i></h4>
    <input
      id="name"
      class=" address_input input_all "
      type="text"
      name="name"
      autocomplete="false"
      v-model="user_name"
      required
      maxlength="255"
      minlength="3"
    />
  
</div>
<div class="col-lg-6 col-md-6 col-12 check_out_col-6">
    <h4 class="account_order_info">{{ $t("Email") }} <i style="color: red"></i></h4>
    <input
      class=" address_input input_all"
      type="email"
      name="email"
      autocomplete="false"
      v-model="email"
      required
      id="email"
    />
              <label v-if="this.$store.state.auth==0"label class="accept_account_email">

                <input type="checkbox" class="account_email_check" @change="funcreate_account_email" id="account_email"/>
                <span>{{ $t('create account') }}</span>
              </label>

  

</div>
<div class="col-lg-6 col-md-6 col-12 check_out_col-6">
  
    <h4 class="account_order_info">{{ $t("Phone") }} <i style="color: red">*</i></h4>
     <input
      id="phone"
      class=" address_input input_all phone_input"
      type="text"
      name="phone"
      autocomplete=""
      v-model="phone"
      required
      :minlength=$store.state.country_length
      :maxlength=$store.state.country_length
    />
    <span class="flag_account">+974</span>
    <label v-if="this.$store.state.auth!=0"label class="accept_account_email">

<input type="checkbox" class="account_email_check" @change="funcreate_account_email" id="account_email"/>
<span>{{ $t('create account') }}</span>
</label>
</div>
<div  v-if="show_password" class="col-lg-6 col-md-6 col-12 check_out_col-6">

    <h4 class="account_order_info">{{ $t("password") }} <i style="color: red">*</i></h4>
    <input
      id="password"
      class=" address_input input_all"
      type="password"
      name="name"
      autocomplete="new-password"
      v-model="password"
      required
      minlength="8"
    />
 
</div>

<!--editttttt check if match-->
<div  v-if="show_password" class="d-flex flex-row justify-content-start account_button">
  <button  @click="create_account" type="submit" class="butt_green cart_butt center_align guest_btn_account">
    {{ $t("CreateAccount") }}
  </button> 
  <div v-if="loading_account" class="empty text-center center-text">
                   
                   <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                    <br>
  
                  
      </div>
  

</div>
<div
    v-if="errorMessage != ''"
    style="
      text-align: center;
      padding: 5px;
      background-color: #fff;
      display: inline;
      margin: 0 10px;
    "
  >
    <span style="margin: 0; color: red; display: inline">{{
      errorMessage
    }}</span>
  </div>
</div>


        <div  v-if="show_guest" class="row confirm_order_method" style="margin-top: 5%;">
          <div class="col-lg-6 col-md-6 col-12 check_out_col-6">
            <div>
              <h4 id="delivery_title" style="color:var(--primary-color)">{{ $t('Receiving method') }}</h4>
              <div v-for="(item, index) in this.DBitems.delivery_type.types" :key="index">
                <input type="radio" name="delivery_option" class="delivery_option " :id="'delivery' + item.id"
                :value="item.id" @click="selectDeliveryTypeId(item.id)">
  
                <img :src="'/theme10/images/delivery' + item.id + '.png'" style="width:6%;margin: 10px;">
                <label :for="'delivery' + item.id" class="method" style="line-height: 3;min-width:150px">
                  {{ item.type }}</label>
          
              </div>
  
  
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12" id="payment_method">
            <div>
              <h4 id="payment_title" style="color:var(--primary-color)">{{ $t('Payment method') }}</h4>
              <div v-for="(item, index) in this.DBitems.payment_types.types" :key="index">
                <input @click="selectPaymentTypeId(item.id)" type="radio" id="" name="radio_payments" :value="item.id"
                  class="payment_method_check">
                <img :src="'/theme10/images/payment' + item.id + '.png'" style="width:8%;margin: 10px;">
                <label class="method method_pay" style="line-height: 3;min-width:90px">
                  {{ item.type }}</label>
                
                <br>
              </div>
  
  
  
            </div>
          </div>
        </div>
        <div v-if="showAddress" class="mt-1">
          
<!--  -->
<div class="row">
  <div class="col-lg-6 col-md-6 col-12 check_out_col-6">
<h4 style="margin-bottom: 30px">{{ $t('selectAddress') }}:</h4>
          </div>
          </div>
<div class="row">
 
<div class="col-lg-6 col-md-6 col-12 check_out_col-6">


<div class="mb-4">
<label class="font-semibold" for="city">
{{ $t('city') }} *:</label>

<v-select v-model="city" :reduce="(option) => option.id" :options="cities" label="city"
:placeholder="$t('city')" class="form-control address_input all_input" id="city">
</v-select>

</div>
</div>

<div class="col-lg-6 col-md-6 col-12 check_out_col-6">

<div class="mb-4">
<label class="font-semibold" for="region">
{{ $t('region') }} *:</label>

<v-select v-model="region" :reduce="(option) => option.id" :options="regions" label="city"
:placeholder="$t('region')" class="form-control all_input address_input" id="region">
</v-select>

</div>
</div>
</div>

<div class="row">
<div class="col-lg-6 col-md-6 col-12 check_out_col-6">
<div class="mb-4">
<label class="font-semibold" for="street">
{{ $t('street') }} :</label>
<input class="input_all address_input" autocomplete="false" required
type="text" :placeholder="$t('street')" maxlength="255" id="street" v-model="street">
</div>
</div>
<div class="col-lg-6 col-md-6 col-12 check_out_col-6">
<div class="mb-4">
<label class="font-semibold" for="building">
{{ $t('building') }} :</label>
<input class="input_all address_input" name="name" autocomplete="false"
type="text" :placeholder="$t('building')" maxlength="255" v-model="building" id="building">
</div>
</div>
</div>
<div class="row">
<div class="col-12 check_out_col-6">
<div class=" mb-4">
<label class="font-semibold" for="detailes">
{{ $t('OtherDetails') }} :</label>
<textarea v-model="other_details" class="input_all address_input" id="detailes">
      </textarea>
</div>
</div>
</div>
<!--  -->


</div>
      
  
        <div  v-if="show_guest" class="d-flex justify-content-between div2_checkout">
          <input type="text" class="code" v-model="disCode" :placeholder="$t('Type Discount code here')">
          <button @click="Couponcode()" class="butt_green apply ">{{ $t('apply') }}</button>
        </div>
        <span style="color:red;text-align: center">{{ couponMsg }}</span>
        <div style="margin-top: 4%; text-align: center;" class="price_table_confirm">
  
          <table class="confirm_table">
            <tr>
              <td class="title_confirm"> <span class="span_checkout"> {{ $t('subTotal') }} : </span></td>
              <td class="value_confirm"> <span class="span_checkout">{{ (PartialPrice * $store.state.rate).toFixed($store.state.price_digit) }} {{ $store.state.UnitPrice
                  }}</span>
              </td>
            </tr>
  
            <tr v-if="this.discountPrice != 0">
              <td class="title_confirm">
                <span class="span_checkout">{{ $t('discount') }} ({{ discountPrice }} %) </span>
              </td>
              <td class="value_confirm"><span class="span_checkout">{{ (PartialPrice * $store.state.rate).toFixed($store.state.price_digit) }}
                  {{ $store.state.UnitPrice }} </span></td>
            </tr>
  
            <tr v-if="this.selectDeliveryType == 5">
              <td class="title_confirm"><span class="span_checkout">{{ $t('Delivery Fee') }}: </span></td>
              <td class="value_confirm"><span class="span_checkout">{{ (deliveryPrice * $store.state.rate).toFixed($store.state.price_digit) }}
                  {{ $store.state.UnitPrice }} </span></td>
            </tr>
  
            <tr v-if="$store.state.tax > 0">
              <td class="title_confirm">
                <span class="span_checkout">{{ $t('tax') }}: ( {{ $store.state.tax }}% ) </span>
              </td>
              <td class="value_confirm">
                <span class="span_checkout" style=" color: var(--primary-color);"> {{
                  ($store.state.rate * (
                    (this.$store.state.tax *
                      (this.PartialPrice -
                        (this.discountPrice * this.PartialPrice) / 100 +
                        this.deliveryPrice)) /
                    100)
                  ).toFixed($store.state.price_digit)
                }} {{ $store.state.UnitPrice }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="title_confirm"> <span class="span_checkout">{{ $t('total') }} </span></td>
              <td class="value_confirm"><span class="span_checkout" style=" color: var(--primary-color);">{{
                (totalPrice * $store.state.rate).toFixed($store.state.price_digit) }} {{ $store.state.UnitPrice }}</span></td>
            </tr>
          </table>
  
        </div>
        <button v-if="!loadingMethods && this.DBitems != null  &&show_guest" class="butt_green cart_butt center_align" style="margin-top: 2%;"
          id="confirm_btn" @click="confirmOrder()"> {{ $t('Continue to checkout') }}</button>
  
          <div v-if="loading_checkout||loadingMethods" class="empty text-center center-text">
                   
                   <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                    <br>
  
                    </div>
      </div>
      <br>
    
    </section>
  </template>

<script>
import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import { HollowDotsSpinner } from "epic-spinners";

export default {
  components: {
    Vodal,
    HollowDotsSpinner
  },
  data: function () {
    return {
      loading_account:false,
      errorMessage:null,
      loading: true,
      user_name: null,
      phone: null,
      email: null,
      items: [],
      password:null,
      products: [],
      products_cost: [],
      loadingMethods: false,
      addresses: [],
      addAddress: false,
      showAddress: false,
      showMonthes: false,
      DBitems: null,
      discount_delivery_free: 0,
      selectPaymentType: null,
      selectDeliveryType: null,
      selectAddress: null,
      count_month: null,
      disCode: null,
      discount_id: null,
      PartialPrice: 0,
      discountPrice: 0,
      deliveryPrice: 0,
      totalPrice: 0,
      couponMsg: "",
      unit: this.$store.state.unit,
      allOrderNote: "",
      langChange: "",
      cities: [],
      regions: [],
      city: null,
      region: null,
      street: null,
      building: null,
      street: null,
      other_details: null,
      show_guest: false,
      show_password:false,
    };
  },
  created() {
   
    if (this.$i18n.locale == 'ar') {
      this.unit = this.$store.state.unitAR;
    }
    else {
      this.unit = this.$store.state.unit;
    }

    //
    let AllCookies = this.$cookies.keys();
    this.items = [];
    this.products = [];
    this.products_cost = [];
    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        console.log('$$$$$$ ')

        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));
        ////PartialPrice
        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.PartialPrice += this.$cookies.get(AllCookies[i]).offer_price;

          console.log('$$$$$$ offer price ', i, '  ', this.PartialPrice)
        } else {

          this.PartialPrice += this.$cookies.get(AllCookies[i]).total_price;
          console.log('$$$$$$  total_price ', i, ' ', this.PartialPrice)
        }
        // console.log('products',pluck(this.products,"product_id"));
      }
    }
    for (let i = 0; i < this.products.length; i++) {
      this.products_cost.push(this.products[i]['product_id']);
    }
    console.log('product_cost: ', this.products_cost);


    this.loadingMethods = true;
    if(this.products_cost.length!=0){ 
      this.axios
      .post(
        this.$store.state.base_api_url +
        this.$store.storeName +
        "/order/create/v2",
        {
          products: this.products_cost,
        },
        {

          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data;
      })
      .then((this.loadingMethods = false,this.loading = false))
      .catch((error) => {
        if (error.response.status == "401") {

        }
      });

    }

    this.axios
      .get(
        this.$store.state.base_api_url +
        this.$store.storeName +
        "/guest/addresses/create",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.cities = response.data.data.cities;
      }).then();

  },
  watch: {
    langChange() {
      //  this.loading = false;
      if (this.$i18n.locale == 'ar') {
        this.unit = this.$store.state.unitAR;
      }
      else {
        this.unit = this.$store.state.unit;
      }
      //get profile

      //
      let AllCookies = this.$cookies.keys();
      this.items = [];
      this.products = [];
      this.products_cost = [];
      this.PartialPrice = 0;
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));
          ////PartialPrice
          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
            this.PartialPrice += this.$cookies.get(AllCookies[i]).offer_price;
          } else {
            this.PartialPrice += this.$cookies.get(AllCookies[i]).total_price;
          }
          // console.log('products',pluck(this.products,"product_id"));
        }
      }

      for (let i = 0; i < this.products.length; i++) {
        this.products_cost.push(this.products[i]['product_id']);
      }
      console.log('product_cost: ', this.products_cost);


      ///////////methods
      this.loadingMethods = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v2",
          { products: this.product_cost },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data;
          // console.log(this.addresses);
        })
        .then((this.loadingMethods = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";
          }
        });


      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/addresses/create",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.cities = response.data.data.cities;
        }).then(this.loading = false);
    }
    ,
    PartialPrice() {
      //$store.state.tax
      //console.log("this.$store.state.tax "+ this.$store.state.tax + "  " +this.$store.state.tax+  this.PartialPrice)
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;
      /*  let x= this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 );
            this.totalPrice = x + ( (this.$store.state.tax *  x) / 100 ) +this.deliveryPrice;*/
      /* this.totalPrice =
               this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
             console.log("PartialPrice Change" + this.totalPrice);*/
    },
    discountPrice() {
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;
      /* this.totalPrice =
             this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
             console.log("discountPrice Change" + this.totalPrice);*/
    },
    deliveryPrice() {
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;
      /*  this.totalPrice =
              this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
              console.log("deliveryPrice Change" + this.totalPrice);*/
    },
    selectDeliveryType() {
      if (this.selectDeliveryType == 5) {
        this.showAddress = true;
        //console.log("this.showAddress" + this.showAddress);
      } else {
        this.showAddress = false;
      }
    },
    selectPaymentType() {
      if (this.selectPaymentType == 4) {
        this.showMonthes = true;
      } else {
        this.showMonthes = false;
        this.count_month = null;
      }
      //console.log( this.count_month)
    },
    city(newVal) {
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/addresses/regions/" +
          this.city,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.regions = response.data.data.regions;
          if (this.regions.find((o) => o.id == this.region) == null) {
            this.region = null;
          }
        });
    },

    region(newVal) {
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/cost_delivery",
          {
            region_id: newVal,
            delivery_id: 5,
            cost: this.PartialPrice,
            products: this.products_cost,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            if (this.discount_delivery_free == 0) {
              // console.log(this.discount_delivery_free);
              this.deliveryPrice = response.data.data.cost;
            }
            this.selectDeliveryType = 5// id;
            //console.log(this.selectDeliveryType);
            for (
              let el = 0;
              el < this.DBitems.delivery_type.types.length;
              el++
            ) {
              this.DBitems.delivery_type.types[el].isActive = false;
              if (this.DBitems.delivery_type.types[el].id == 5) {
                this.DBitems.delivery_type.types[el].isActive = true;
              }
              //console.log(this.DBitems.delivery_type.types[el].isActive);
            }
            // this.discountPrice = response.data.data.discount;
          } else {
            alert(response.data.message);
            this.selectAddress = null;

            // const $select = document.querySelector("#addressSelect");
            //$select.value = "SelectOne";
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .then(() => {
          this.loadingMethods = false;
        })
        .catch((error) => {
          if (error.response.status == "401") {
            //  this.$localStorage.remove(this.$store.storeName);
            // window.location.href =
            //   "/";
          }
        });
    },

  },
  methods: {
    funshow_guest() {
      if (this.show_guest == false)
        this.show_guest = true;
      else
        this.show_guest = false;

    },
    close_address() {
      this.$store.state.addAddress = false;
    },
    open_address() {
      this.$store.state.addAddress = true;
    },
    updatAddress() {
      ///////////addresses
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.addresses = response.data.data.addresses.addresses;
          /*if (this.addresses.length != 0) {
            this.selectAddress = this.addresses[0].id;
          }*/
          // console.log(this.addresses);
        })
        .then((this.loading = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/";
          }
        });
    },
    updatedata2() {
      console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj')
      this.langChange_check = false;
      this.langChange = false;
      ///////////methods
      this.loadingMethods = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v2",
          { products: this.product_cost },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data;
          // console.log(this.addresses);
        })
        .then((this.loadingMethods = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";
          }
        });


      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/addresses/create",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.cities = response.data.data.cities;
        }).then(this.loading = false);

    },
    Couponcode() {
      this.loading = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/use_coupon",
          {
            code: this.disCode,
            total_price: this.PartialPrice,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            this.couponMsg = "";
            this.discountPrice = response.data.data.discount;
            this.discount_id = response.data.data.discount_id;
            this.discount_delivery_free = response.data.data.isdelivery_free;
            if (this.discount_delivery_free == 1)
              this.deliveryPrice = 0;
            /////////for select
            // this.selectAddress = null;
            // this.deliveryPrice = 0;
            ////////////
          } else {
            this.couponMsg = response.data.message;
            /////////for select
            // this.selectAddress = null;
            // this.deliveryPrice = 0;
            ////////////
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .then((this.loading = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/";
          }
        });
    },
    selectPaymentTypeId(id) {
      document.getElementById('payment_title').style = "color:black";
      this.loadingMethods = true;
      this.selectPaymentType = id;
      //console.log(this.selectPaymentType);

      for (let el = 0; el < this.DBitems.payment_types.types.length; el++) {
        this.DBitems.payment_types.types[el].isActive = false;
        if (this.DBitems.payment_types.types[el].id == id) {
          this.DBitems.payment_types.types[el].isActive = true;
        }
        //console.log(this.DBitems.payment_types.types[el].isActive);
      }

      //setTimeout(() => {
      this.loadingMethods = false;
      //  }, 3000);
    },
    selectDeliveryTypeId(id) {
      //  this.loadingMethods = true;
      console.log("bbbbbbbbbbbbbbbbbbb");
      this.selectDeliveryType = id;
      document.getElementById('delivery_title').style = "color:black";
      for (
              let el = 0;
              el < this.DBitems.delivery_type.types.length;
              el++
            ) {
              this.DBitems.delivery_type.types[el].isActive = false;
              if (this.DBitems.delivery_type.types[el].id == id) {
                this.DBitems.delivery_type.types[el].isActive = true;
              }
              //console.log(this.DBitems.delivery_type.types[el].isActive);
            }
    },

    onChange: function (event) {
      this.selectAddress = event.target.value;
      this.selectDeliveryTypeId(5);
    },
    onChangeMonths: function (event) {
      //console.log( event.target.value)
      this.count_month = event.target.value;
    },
    confirmOrder() {
      this.change_state();
      document.getElementById("confirm_btn").disabled = true;
      var count_error = 0;
      var error_msg = "";

      if (this.selectPaymentType == null) {
        count_error++;
        document.getElementById('payment_title').style = 'color:red'

        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب تحديد طريقة الدفع");
        } else {
          error_msg += ("Please select a payment type ");
        }
      }

      if (this.user_name == null) {
        count_error++;
        document.getElementById('name').style = 'border:2px solid red !important'

        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب ادخال الاسم");
        } else {
          error_msg += (" Please Enter Name");
        }
      }

      if (this.selectDeliveryType == null) {
        document.getElementById('delivery_title').style = 'color:red'

        count_error++;
        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب تحديد طريقة التوصيل");
        } else {
          error_msg += (" Please select a delivery type");
        }
      }

      if (this.selectDeliveryType == 5 && this.region == null) {
        document.getElementById('region').style = 'border:2px solid red !important'

        count_error++;
        if (this.$i18n.locale == "ar") {
          error_msg += ("يجب اختيار المنطقة ");
        } else {
          error_msg += (" Please Select A Region");
        }
      }

   

      if (this.phone == null) {
        count_error++;
        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب ادخال الهاتف");
        } else {
          error_msg += (" Please Enter Phone");
        }
        document.getElementById('phone').style = 'border:2px solid red !important'
      }
      if (count_error == 0) {
        this.loading = true;
        this.axios
          .post(
            this.$store.state.base_api_url +
            this.$store.storeName +
            "/order/store/v2",
            {

              delivery_type_id: this.selectDeliveryType,
              payment_type_id: this.selectPaymentType,
              total_price: this.totalPrice,
              discount_id: this.discount_id,
              products: this.products,
              part_price: this.PartialPrice,
              note: this.allOrderNote,
              count_month: this.count_month,
              device: 'web',
              user_name: this.user_name,
              phone: this.phone,
              email: this.email,
              region_id: this.region,
              street: this.street,
              building: this.building,
              other_details: this.other_details

            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            if (response.data.code == "1") {
              if (response.data.data.clear_cart == null || response.data.data.link_pay == null) {
                for (let i = 0; i < this.items.length; i++) {
                  this.$cookies.remove(this.items[i]);
                }
                this.items = [];

                console.log("hiiiiiiii" + this.$cookies.keys());
                this.$store.state.cartCounter = 0;
              }

              //console.log("hiiiiiiii" + this.$cookies.keys());

              if (response.data.data.link_pay == null) {
                this.$store.state.cartCounter = 0;
                this.$router.push({
                  name: "order_done",
                  params: {
                    storeName: this.$store.storeName,
                    lang: this.$i18n.locale,
                    id: response.data.data.order_id
                  },
                });
              }

              if (response.data.data.link_pay != null) {
                window.location.href = response.data.data.link_pay;
              }

              //this.deliveryPrice = response.data.data.cost;
              // this.discountPrice = response.data.data.discount;
            } else {
              alert(response.data.message);
              document.getElementById("confirm_btn").disabled = false;
              var count_error = 0;
              var error_msg = "";
              // this.couponMsg = response.data.message;
            }
            //this.addresses = response.data.data.addresses.addresses;
            // console.log(this.addresses);
          })
          .then((this.loading = false))
          .catch((error) => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              // window.location.href =
              //   "/"+this.$i18n.locale ;
            }
          });
      }
      else {
        alert(error_msg);
        document.getElementById("confirm_btn").disabled = false;

      }


      //////////

      //////////

    },
    change_state() {
      document.getElementById('payment_title').style = "color:black";
      document.getElementById('delivery_title').style = "color:black";
      if (document.getElementById('region'))
        document.getElementById('region').style = "border: 1px solid var(--primary-color) !important;";
      document.getElementById('phone').style = "border: 1px solid var(--primary-color) !important;";
      document.getElementById('name').style = "border: 1px solid var(--primary-color) !important;";

    },
    funcreate_account_email(){
console.log('11111111111')

      if(document.getElementById('account_email').checked==true)
      this.show_password=true;
    else
    this.show_password=false;
    },

    create_account(){

      var count_error = 0;
      var error_msg = "";
      document.getElementById('phone').style = "border: 1px solid #cacaca !important;";
      document.getElementById('name').style = "border: 1px solid #cacaca !important;";
      document.getElementById('email').style = "border: 1px solid #cacaca !important;";
      document.getElementById('password').style = "border: 1px solid #cacaca !important;";

      if (this.phone == null) {
        count_error++;
        document.getElementById('phone').style = 'border:2px solid red !important'


        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب إدخال الرقم");
        } else {
          error_msg += ("Please Add Phone ");
        }
      }


      if (this.user_name == null) {
        count_error++;
        document.getElementById('name').style = 'border:2px solid red !important'


        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب إدخال الاسم");
        } else {
          error_msg += ("Please Add User Name ");
        }
      }

      if (this.password == null) {
        count_error++;
        document.getElementById('password').style = 'border:2px solid red !important'


        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب إدخال كلمة السر");
        } else {
          error_msg += ("Please Enter Password ");
        }
      }

      if (this.email == null&& this.$store.state.auth==0) {
        count_error++;
        document.getElementById('email').style = 'border:2px solid red !important'


        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب إدخال الايميل");
        } else {
          error_msg += ("Please Add Email");
        }
      }
      if (count_error == 0) {
         this.loading_account=true;        
          console.log(this.phone)
         
          this.axios
            .post(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/register",
                {
                phone:this.$store.state.country_code+this.phone,
                name:this.user_name,
                email:this.email,
                password:this.password
              
              },

              {
                
                headers: {
                  "Accept-Language": this.$i18n.locale,
                },
              }
            )
            .then((response) => {
              this.loading_account=false;
             
              if (response.data.code == "1") {
                
                this.$localStorage.set(
                  this.$store.storeName,
                  response.data.data.token
                );
                this.$localStorage.set(
              this.$store.user_name,
              response.data.data.name
            );
            if(!response.data.data.name||response.data.data.name=='')
             {
              this.$localStorage.set(
              this.$store.user_name,
            );
             }         
                console.log(
                  this.$localStorage.get(this.$store.storeName) +
                    " id : " +
                    this.$store.storeName
                );

                console.log(
                  "user token : " +
                    this.$localStorage.get(this.$store.storeName)
                );
               
                window.location.href=  '/checkout' ;
                this.loading=false;
              } else if (response.data.code == "-1") {
                this.loading=false;
                this.errorMessage = response.data.message;
                setTimeout(() => {
                  this.errorMessage = "";
                  this.count_error=0;
                }, 3000);
              }
              if (response.data.error) {
                this.errorMessage = "response.data.error";
                setTimeout(() => {
                  this.errorMessage = "";
                }, 3000);
              }
             
            });
        } 
        else{ 
          alert(error_msg);
            setTimeout(() => {

var count_error = 0;
var error_msg = "";
document.getElementById('phone').style = "border: 1px solid #cacaca !important;";
document.getElementById('name').style = "border: 1px solid #cacaca !important;";
document.getElementById('email').style = "border: 1px solid #cacaca !important;";
document.getElementById('password').style = "border: 1px solid #cacaca !important;";
}, 8000);}
        

     
    
    }
  },
};

</script>
